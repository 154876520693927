import { 
  errorMessageHelpers,
  formatedDateItem
} from '@/_helpers/funciones';
//Data:
function data() {
  return {}
}

//Meodos:
function hasError(field) {
  return field.$dirty ? !field.$error : null;
}
function errorMessage(field){
  return errorMessageHelpers(this, field);
}
function isTimeInvalid(field) {
  return field.$dirty ? field.$invalid : null
}
function inputTimeClasses(field) {
  return {
    'time-invalid': this.isTimeInvalid(field),
    'time-valid': field.$dirty ? !this.isTimeInvalid(field) : null
  }
}
function clearInputs() {
  this.resetForm();
  this.$v.$touch();
}

//Computeds
function isSubmitValid() {
  return this.$v.$invalid || this.isSubmit;
}

export const TurnoMixin = {
  data,
  methods: {
    hasError,
    isTimeInvalid,
    inputTimeClasses,
    clearInputs
  },
  computed: {
    isSubmitValid
  }
}

export const MovimientoMixin = {
  data,
  methods: {
    hasError,
    errorMessage,
    clearInputs
  },
  computed: {
    isSubmitValid
  }
}

export const TransaccionMixin = {
  data,
  methods: {
    hasError,
  },
  computed: {
    isSubmitValid
  }
}

function loadData(flag) {
  this.isLoadingGeneral = true;
  let peticiones = [
    this.$http.ejecutar('GET', 'TpVessel-list', { Filter: 'ACTIVO' }),
    this.$http.ejecutar('GET', 'StowageRole-list', { Filter: 'ACTIVO' })
  ];

  Promise.all(peticiones)
  .then((responses) => {
    this.itemsVesselTypeList = responses[0].data.data;
    this.rolesList = responses[1].data.data;

    if(flag){
      this.itemsVesselTypeChecked = responses[0].data.data.find((tpBuque) => tpBuque.TpVesselId == this.roleEstibaData.TpVesselId);
      this.roleEstibaBuque.TpVesselId = this.itemsVesselTypeChecked?this.itemsVesselTypeChecked.TpVesselId:'';

      this.role = responses[1].data.data.find((rol) => rol.StowageRoleId == this.roleEstibaData.StowageRoleId);
      this.roleEstibaBuque.StowageRoleId = this.role?this.role.StowageRoleId:'';
    }
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGeneral = false;
  });
}

function loadDataShiftVesselType(flag) {
  this.isLoadingGeneral = true;
  let peticiones = [
    this.$http.ejecutar('GET', 'TpVessel-list', { Filter: 'ACTIVO' }),
    this.$http.ejecutar('GET', 'Turn-list', { Filter: 'ACTIVO' })
  ];

  Promise.all(peticiones)
  .then((responses) => {
    this.vesselTypesList = responses[0].data.data;
    this.turnsList = responses[1].data.data;

    if(flag){
     this.vesselType = responses[0].data.data.find((tpBuque) => tpBuque.TpVesselId == this.shiftVesselTypeData.TpVesselId);
     this.shiftVesselType.TpVesselId = this.vesselType ? this.vesselType.TpVesselId : '';

      this.turn = responses[1].data.data.find((rol) => rol.TurnId == this.shiftVesselTypeData.TurnId);
      this.shiftVesselType.TurnId = this.turn ? this.turn.TurnId : '';
    }
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGeneral = false;
  });
}

function isRolesEmpty(){
  return this.rolesList.length == 0 || this.showCollapseRole;
}

function isRoleValid() {
  return {
    'invalid-feedback': true,
    'invalido': this.role? null : this.$v.$anyDirty? this.$v.role.$invalid : null
  };
}
function isTurnsEmpty(){
  return this.turnsList.length == 0;
}

function isTurnValid() {
  return {
    'invalid-feedback': true,
    'invalido': this.turn? null : this.$v.$anyDirty? this.$v.turn.$invalid : null
  };
}

function isVesselTypesEmpty(){
  return this.vesselTypesList.length == 0;
}

function isVesselTypeValid() {
  return {
    'invalid-feedback': true,
    'invalido': this.vesselType ? null : this.$v.$anyDirty? this.$v.vesselType.$invalid : null
  };
}
export const ShiftVesselTypeMixin = {
  data(){
    return {
      isLoadingGeneral: false
    }
  },
  methods: {
    hasError,
    loadDataShiftVesselType,
    formatedDateItem,
  },
  computed: {
    isSubmitValid,
    isTurnsEmpty,
    isTurnValid,
    isVesselTypesEmpty,
    isVesselTypeValid,
  }
}

export const RoleMixin = {
  data(){
    return {
      isLoadingGeneral: false
    }
  },
  methods: {
    hasError,
    loadData,
    formatedDateItem,
  },
  computed: {
    isSubmitValid,
    isRolesEmpty,
    isRoleValid
  }
}
export const RoleCollapseMixin = {
  data() {
    return{
      showCollapse: false,
      roleEdit: false
    }
  },
  methods: {
    openCollapse(add) {
      if(!add)
        this.roleEdit = true;
  
      this.showCollapse = true;
    },
    handleClose() {
      this.showCollapse = false;
      this.roleEdit = false;
      this.role = null;
      this.getRoles();
    }
  },
  computed: {
    isCollapseOpen() {
      return this.showCollapse;
    },
    isRoleEditInvalid() {
      return  this.role? this.isCollapseOpen : true;
    }
  }
}

export const RoleSingleCollapseMixin = {
  data() {
    return{
      showCollapseRole: false,
      roleEdit: false
    }
  },
  methods: {
    openCollapse(add) {
      if(!add)
        this.roleEdit = true;
  
      this.showCollapseRole = true;
    },
    handleClose() {
      this.showCollapseRole = false;
      this.roleEdit = false;
      this.role = null;
      this.getRoles();
    }
  },
  computed: {
    isCollapseOpen() {
      return this.showCollapseRole;
    },
    isRoleEditInvalid() {
      return  this.role? this.isCollapseOpen : true;
    }
  }
}


export const GpoSupplyMixin = {
  data(){
    return {
      isLoadingGeneral: false
    }
  },
  methods: {
    hasError
  },
  computed: {
    // isSubmitValid
  }
}

function getBadge(status) {
    switch (status) {
      case 'ACTIVO':
        return 'success'
      case 'INACTIVO':
        return 'danger'
    }
  }

export const GrupoMixin = {
  data,
  methods: {
    hasError,
    clearInputs,
    getBadge
  },
  computed: {
    isSubmitValid
  }
}

export const InsumoMixin = {
  data,
  methods: {
    hasError,
    errorMessage,
    clearInputs,
    getBadge
  },
  computed: {
    
  }
}